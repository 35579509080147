// Dialog
.app-mat-dialog-container__wrapper {

	.mat-dialog-container {
    margin: 50px 0 50px !important;
	   	padding: 0px;
	   	overflow: visible;
	}
}

.mat-dialog-actions {
	margin-bottom: 0 !important;
}

// .backdropBackground {
//   background: rgba(26,33,35, 0.4);
// }

.cdk-overlay-container {

    z-index: 9999 !important;
    overflow: visible !important;
}

.cdk-overlay-backdrop {
  //display: none;
  background: rgba(26,33,35, 0.4);
  //height: 200vh;
  //overflow: auto !important;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: auto;
}

.cdk-global-overlay-wrapper {
  background: rgba(26,33,35, 0.4);
  overflow: auto !important;
}

.progress-bar-top {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 3px !important;
}

.mat-progress-bar-fill::after {
  background-color: #192951;
}
.mat-progress-bar-buffer {
    background: #f7f7f7;
}
.mat-progress-bar {
    height: 3px !important;
}

.success .mat-progress-bar-fill::after {
  background-color: #43c797;
}

.warning .mat-progress-bar-fill::after {
  background-color:#f1b44c;
}

.error .mat-progress-bar-fill::after {
  background-color: #f90202;
}
