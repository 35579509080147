//
// Range slider
//

.ngx-slider {
  .ngx-slider-pointer {
    background-color: $primary !important;
    width: 18px !important;
    height: 18px !important;
    top: -7px !important;
    outline: none !important;

    &:after {
      top: 5px !important;
      left: 5px !important;
    }
  }

  .ngx-slider-active {
    &:after {
      background-color: $white !important;
    }
  }

  .ngx-slider-tick {
    left: -3px !important;
  }

  .ngx-slider-tick-value {
    left: 1px !important;
    top: -25px !important;
  }

  .ngx-slider-tick-legend {
    top: 14px !important;
    text-transform: capitalize;
    font-size: 0.7rem;
  }

  .ngx-slider-selection {
    background: $primary !important;
  }
}
