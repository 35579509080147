//
// Forms.scss
//


// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.25rem;

  .form-check-input {
    right: 0;
    margin-left: 0;
  }

  .form-check-label {
    display: block;
  }
}


.custom-control-right {
  padding-left: 0;
  padding-right: 1.5rem;

  .custom-control-label {
    display: inline-block;

    &:before,
    &:after {
      left: auto;
      right: -1.5rem;
    }
  }

  .custom-control-input {
    left: auto;
  }
}

.form-control.ng-select {
  border: none;
  padding: 0;
}

.state-error {
  background-color: #ffcccc;
  border-color: #ff0000;
}

.ng-select.state-error .ng-control {
  background-color: #ffcccc;
  border-color: #ff0000;
}

.form-control:disabled,
.form-control:read-only,
input:read-only,
textarea:read-only {
  background-color: #EEF6F9!important;
}

.country-flag {
  width: 25px !important;
  height: 15px !important;
  margin-right: 10px !important;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}


select {
  background: #fff !important;
}