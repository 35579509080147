// Add globel styles and module imports...
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

body{
  background-color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
}
// for the page title
// .page-title-box h4 {
//   font-size: 20px!important;
// }
.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

  .custom-day.focused {
    background-color: #e6e6e6;
  }

  .custom-day.range,
  .custom-day:hover {
    background-color: #192951;
    color: white;
  }

  .custom-day.faded {
    background-color: #192951;
  }
  // btn
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.15rem transparent!important;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.15rem transparent;
}
// ng-select
.ng-select-container {
  border-bottom: 1px solid red !important;
}
// // Breadcrumb
// .breadcrumb-item > a {
//   color: #495057;
//   font-weight: 600;
//   font-size:16px;
// }
// .breadcrumb-item.active {
//   color: #74788D;
//   font-weight: 600;
//   font-size:16px;

// }
// for filter
.rounded {
  border-radius: 1.5rem !important;
  border-color: #CED4DA;

}
.rounded:hover,.rounded:active,.rounded:focus{
  background-color: transparent!important;
  box-shadow: transparent!important;
}

/*pagination*/
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.85rem!important;
  margin-left: -1px;
  line-height: 1.25;
  color: #74788d;
  background-color: #fff;
  border: 1px solid #ced4da;
}

  /* table css changes*/

.table {
  thead{
    th{
      vertical-align: bottom;
      border-bottom: 2px solid #eff2f7;
      background-color: #F8F9FA!important;
      font-size:16px;
    }
  }
  tbody{
    tr{
      td{
        color:#495057!important;
        font-size:16px;
        p{
          color: #74788D!important;
        }
      }
    }

  }
}

.badge-success {
  color: #fff;
  background-color: #43c797!important;
  padding: 6px 6px 6px 6px!important;
  //border-radius: 13px!important;
  //width: 100%!important;
  //font-weight: 400!important;
}

.badge-grey {
  color: #fff;
  background-color: #ccc !important;
  padding: 6px 6px 6px 6px !important;
  //border-radius: 13px!important;
  //width: 100%!important;
  //font-weight: 400!important;
}

.badge-success-light {
  color: #fff;
  background-color: #a1e3cb !important;
  padding: 6px 6px 6px 6px !important;
  //border-radius: 13px!important;
  //width: 100%!important;
  //font-weight: 400!important;
}

.badge-warning{
  color: #fff;
  background-color: #F1B44C!important;
  padding: 6px 6px 6px 6px!important;
}

.badge-info{
  color: #fff;
  background-color: #a7d2f8!important;
  padding: 6px 6px 6px 6px!important;
}

.badge-info-light {
  color: #fff;
  background-color: #50A5F1 !important;
  padding: 6px 6px 6px 6px !important;
}

.badge-danger{
  color: #fff;
  background-color: #F90202 !important;
  padding: 6px 6px 6px 6px!important;

}

.badge-danger-light {
  color: #fff;
  background-color: #fc8080 !important;
  padding: 6px 6px 6px 6px !important;

}

// for kys status color
.badge-soft-success{
  color: #fff;
  background-color: #43c797!important;
  padding: 6px 6px 6px 6px!important;
  border-radius: 6px!important;
  //width: 100%!important;
  //font-weight: 400!important;

}
.badge-soft-danger{
  color: #fff;
  background-color: #F90B0B!important;
  padding: 6px 6px 6px 6px!important;
  border-radius: 6px!important;
  //width: 100%!important;
  font-weight: 400!important;

}
.badge-soft-warning{
  color: #fff;
  background-color:  #F1B44C!important;
  padding: 6px 6px 6px 6px!important;
  border-radius: 6px!important;
  //width: 100%!important;
  font-weight: 400!important;
}
.badge-soft-info{
  color: #fff;
  background-color:  #50A5F1!important;
  padding: 6px 6px 6px 6px!important;
  border-radius: 6px!important;
  //width: 100%!important;
  font-weight: 400!important;
}

// card bg color
.bg-soft-success {
  background: rgba(67,
    199,
    151, 0.2) !important;
}
.bg-soft-danger {
  background: rgba(249,
    11,
    11, 0.1) !important;
}
.bg-soft-warning {
  background: rgba(241,
    180,
    76, 0.1) !important;
}
.bg-soft-info {
  background: rgba(86,
    110,
    230, 0.1) !important;
}
.bg-success {
  background: rgba(67,
    199,
    151, 1) !important;
}

.bg-danger {
  background: rgba(249,
      11,
      11, 1) !important;
}

.bg-warning {
  background: rgba(241,
      180,
      76, 1) !important;
}

.bg-info {
  background: rgba(80,
    165,
    241, 1) !important;
}


// for rating
.badge-primary{
  color: #fff;
  background-color:  #4A0BF9!important;

  .mdi-star{
    color:#AFD21C!important;
  }

}
// form label color and form-control color
// label{
//   color: #000000!important;
//   // font-size:13px!important;
// }
.rupees-symbol{
  font-family: Roboto !important;
}

.input--textfield {
  color: transparent !important;
  cursor: pointer;
  text-shadow: 0 0 0 black !important;
  &:focus {
      outline: none !important;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

/*Advance search dropdown on hover text color*/
.ng-select {
    .ng-dropdown-panel{
      .ng-option.ng-option-marked {
        background-color:#eff2f7 !important;
        color: #192951 !important;
        font-weight: 500;
      }
   }
}
.ng-select-container {
  // border-radius: 1.5rem !important;
  border: 1px solid #CED4DA;
  box-shadow: none;
}
// .ng-select{
//     .ng-select-container{
//       .ng-value-container{
//         .ng-placeholder {
//           padding-left: 27px!important;
//         }
//         .ng-value {
//           margin-left: 23px!important;
//         }
//       }
//     }

// }

.bg-dark{
  background-color: #343A40;
}

.form-control:disabled,
.form-control:read-only,
input:read-only,
textarea:read-only,
.clockpicker-disable .form-control:read-only {
  background-color: #EEF6F9!important;
}

.clockpicker-active .form-control:read-only {
  background-color: #FFFFFF!important;
}

.disable-color{
  background-color: #EEF6F9!important;
}

.white-color{
  background-color: #FFFFFF!important;
}
