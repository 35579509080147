// 
// _layouts.scss
// 

body[data-layout-size="boxed"] {
    background-color: $boxed-body-bg;
    #layout-wrapper {
        background-color: $body-bg;
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow;
    }

    #page-topbar {
        max-width: $boxed-layout-width;
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &.vertical-collpsed {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
    }
}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"]{
    #page-topbar, #layout-wrapper, .footer {
        max-width: 100%;
    }
    .container-fluid, .navbar-header {
        max-width: $boxed-layout-width;
    }
}

/* Customize website's scrollbar like Mac OS */

::-webkit-scrollbar {
    background-color:#f6f6f6;
    width:10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    transition: all 0.3s ease;
    background-color:#f6f6f6;
    width:10px;
    height: 10px;
}

::-webkit-scrollbar-track:hover {
    transition: all 0.3s ease;
    background-color:#f6f6f6;
    cursor: n-resize!important;
}

::-webkit-scrollbar-thumb {
    transition: all 0.3s ease;
    background-color:#c1c1c1;
}

::-webkit-scrollbar-thumb:hover {
    transition: all 0.3s ease;
    background-color: darken(#c1c1c1, 3%);
    cursor: n-resize !important; 
}


/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {display:none}